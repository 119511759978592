<template>
  <v-container class="fill-height">
    <div v-if="nouser">
      <v-text-field v-model="authcode" placeholder="authcode" required @keyup.enter="checkauth"></v-text-field>
    </div>
    <!-- <div v-else align="center" justify="center">-->
    <v-row align="center" justify="center">
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false" />
        <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
        <!-- password field-->
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Password"
          counter
          @click:append="show1 = !show1"
        >
        </v-text-field>
        <!--end of Password field-->
        <v-btn v-if="!loggedin" :disabled="!valid" color="primary" class="mr-4" @click="validate"> Login </v-btn>
        <v-btn v-if="!loggedin" color="secondary" class="mr-4" @click="forgotPassword"> Forgot Password </v-btn>
        <!--<v-btn color="error" class="mr-4" @click="reset" >  Reset Form  </v-btn>-->
      </v-form>
    </v-row>
    <!--</div>-->
  </v-container>
</template>

<script>
import { auth, usersRef } from "@/fb"
import { emailMixin } from "@/mixins/emailMixin.js"
import { convertAll } from "@/mixins/convertall.js"
import Alertmsg from "@/components/common/Alertmsg.vue"
export default {
  name: "Login",
  mixins: [emailMixin, convertAll],
  components: {Alertmsg},
  data: () => ({
    valid: true,
    show1: false,
    checkbox: false,
    password: "",
    EmpID: "",
    email: "",
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 6 || "Min 6 characters",
      max: (v) => v.length <= 20 || "Max 25 characters",
      emailMatch: () => "The email and password you entered don't match",
    },
    select: null,
    lazy: false,
    error: "",
    role: [],
    superadmin: false,
    loggedin: false,
    status: null,
    passvalue: { role: {}, menufrom: "" },
    domain: "",
    domainflag: "",
    name: "",
    nouser: false,
    authcode: "",
    mailmsg: {
      to: "",
      subject: "",
      message: "",
    },
    alertflag: false,
    alertmsg: "",
  }), // end of data
  async created() {
    //this.checkuserlist(); // to check the any user is already in the table , if not then ask for the auth code then regirster the default first user
    var loggedin = await this.$store.state.loggedin;
    console.log("login created-loggedin", loggedin)
    if(loggedin){
       var roles = await this.$store.state.userProfile
      console.log("login created-roles", roles)
      this.email=roles.email;
      this.checkrole();
    }
  },

  methods: {
    clearalert() {
      console.log("inside cleartalert")
      this.alertflag = false
      location.reload()
    },

    async validate() {
      console.log("inside validate", this.email, this.domain)
      await auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          console.log("login validated", this.email)

          if (this.email.includes(this.domain)) {
            this.domainflag = true
          }
          this.checkrole()
        })
        .catch((error) => {
          this.error = error
          console.log(" error in validate function ", error.code)
          if (error.code == "auth/wrong-password" || error.code == "auth/user-not-found") {
            this.alertflag = true
            this.alertmsg = "Email ID / password is not valid Please try again"
          } else if (error.code == "auth/too-many-requests") {
            //alert("Too many fail attempt please chose forget ppassword and reset your password");
            this.alertflag = true
            this.alertmsg = "Too many fail attempt please chose forget ppassword and reset your password"
          } else {
            // alert("system error pleae try again")
            this.alertflag = true
            this.alertmsg = "system error pleae try again"
          }
        })
    },

    async forgotPassword() {
      if (this.email == null) alert("please enter the valid email")
      else {
        console.log("inside forgetPassword")
        auth
          .sendPasswordResetEmail(this.email)
          .then(() => {
            // alert("The new password has been sent to your email , please check your email and login");
            this.alertflag = true
            this.alertmsg = "The new password has been sent to your email , please check your email and login"
            this.resetcounter()
          })
          .catch((error) => {
            this.error = error
            console.log(this.error)
            //alert("Error in forgetPassword pls try again");
            this.alertflag = true
            this.alertmsg = "Error in forgetPassword pls try again"
          })
      }
    },
    async resetcounter() {
      var queryresult = await usersRef.where("email", "==", this.email).get()
      usersRef.doc(queryresult.docs[0].id).update({
        lastpasswordchnage: new Date(),
        failattempts: 0,
      })
    },
    async checkrole() {
      var queryresult = []
      console.log(this.email + " checkrole is validated")
      this.loggedin = true
      queryresult = await usersRef.where("email", "==", this.email).get()
      if (queryresult.docs.length == 1) {
        await auth.currentUser.getIdTokenResult().then((idToken) => {
          this.passvalue.role = idToken.claims
        })
        this.status = queryresult.docs[0].data().status
        this.name = queryresult.docs[0].data().name
        this.EmpID = queryresult.docs[0].data().empid
        this.domain = queryresult.docs[0].data().domain
        this.userconfig = queryresult.docs[0].data().userconfig
        var itemsPerPage = this.getitemperpage(this.userconfig)
        this.passvalue.menufrom = "/login"
        this.passvalue.domain = this.domain
        this.passvalue.EmpID = this.EmpID
        this.passvalue.email = this.email
        this.passvalue.name = this.name

        this.passvalue.mgrname = queryresult.docs[0].data().mgrName
        this.passvalue.mgremail = queryresult.docs[0].data().mgremail
        this.passvalue.uid = queryresult.docs[0].id
        this.passvalue.userconfig = this.userconfig
        this.passvalue.itemsPerPage = itemsPerPage
        // the below will be removed when
        /*  this.passvalue.role.superadmin=true
          this.passvalue.role.hradmin=true
          this.passvalue.role.employee=true*/

        console.log("inside login :passvalue", this.passvalue)
        this.$emit("loggedin", this.passvalue) // will be captured by app.vue and sets the nav bar.
        this.$store.dispatch("setuserprofile", this.passvalue)

        if (this.status == "newuser" && this.domainflag) {
          alert(" Hi Welcome to SPP ,please change the password")
          this.$router.push({ name: "changepassword", params: { roles: this.passvalue } })
        } else if (this.status == "deactive") {
          alert("Hi your account is inactive , please check with admin")
        } else if (this.status == "triesexceed") {
          alert("Max Tries exceeded, please choose forgot password and reset the password")
        } else {
          this.role = {}
          this.role = this.passvalue.role
          console.log("inside login page passvalue: ", this.passvalue)
          if (this.role.superadmin) {
            this.$router.push({ name: "register", params: { roles: this.passvalue } })
          } else if (this.role.hradmin) {
            this.$router.push({ name: "hradmin", params: { roles: this.passvalue } })
          } else if (this.role.sysadmin) {
            this.$router.push({ name: "sysadmin", params: { roles: this.passvalue } })
          } else if (this.role.readuser) {
            this.$router.push({ name: "hradmin", params: { roles: this.passvalue } })
          } else if (this.role.employee) {
            this.$router.push({ name: "employee", params: { roles: this.passvalue } })
          } else {
            /*else if( (this.role.con) || (this.role=="employee") || (this.role=="hradmin"))
            {
              this.$router.push({ name: this.role , params:{roles:this.passvalue} } );
            }*/
            alert("Role is not defined please check with admin")
          }
        }
      } // end of  queryresult.docs.length==1
      else {
        console.log("unable to fetch the role for email", queryresult.docs.length, this.email)
        this.alertflag = true
        this.alertmsg = "Role is not defined please check with admin for support"
     
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  }, // end of methods
}
</script>
